
import { Component, Vue } from 'nuxt-property-decorator'
import LayoutLang from '~/components/layout/LayoutLang.vue'
import LayoutMenu from '~/components/layout/menu/Menu.vue'
// import LayoutMerch from '~/components/layout/LayoutMerch.vue'
// import LayoutSearch from '~/components/layout/LayoutSearch.vue'

@Component({
  components: {
    // LayoutMerch,
    // LayoutSearch,
    LayoutMenu,
    LayoutLang
  }
})
export default class extends Vue {
  name: 'LayoutHeader'
}
