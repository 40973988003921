
import { Component, Ref, Vue } from 'nuxt-property-decorator'
import PaintingRate from '~/components/index/PaintingRate.vue'

@Component({
  components: {
    PaintingRate
  }
})
export default class extends Vue {
  @Ref('fullscreenTarget') fullscreenTarget!: HTMLImageElement
  @Ref('fullscreenContainer') fullscreenContainer!: HTMLImageElement

  scale: number = 0

  imgDefaultSize = {
    width: 0,
    height: 0
  }

  mounted () {
    this.imgDefaultSize.width = (document.querySelector('#fullScreenImg') as any).clientWidth
    this.imgDefaultSize.height = (document.querySelector('#fullScreenImg') as any).clientHeight

    this.fullScreen(document.documentElement)
    this.$eventBus.$on('HIDE_FULLSCREEN', () => {
      this.outFullscreen()
    })
    document.addEventListener('fullscreenchange', this.onFullscreenChange)
    document.addEventListener('mozfullscreenchange', this.onFullscreenChange)
    document.addEventListener('webkitfullscreenchange', this.onFullscreenChange)
    document.addEventListener('msfullscreenchange', this.onFullscreenChange)
  }

  fullScreen (element) {
    if (element.requestFullscreen) {
      element.requestFullscreen()
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen()
    } else if (element.mozRequestFullscreen) {
      element.mozRequestFullScreen()
    } else {
      this.$emit('close')
    }
  }

  zoom (step: number, isScale: boolean) {
    if (isScale) {
      this.scale++
      this.imgDefaultSize.width = this.imgDefaultSize.width * step
      this.imgDefaultSize.height = this.imgDefaultSize.height * step
    } else {
      this.scale--
      this.imgDefaultSize.width = this.imgDefaultSize.width / step
      this.imgDefaultSize.height = this.imgDefaultSize.height / step
    }

    if (this.scale < 1) {
      this.fullscreenTarget.style.display = 'flex'
    } else {
      this.fullscreenTarget.style.display = 'block'
    }

    (document.querySelector('#fullScreenImg') as any).style.height = this.imgDefaultSize.height + 'px';
    (document.querySelector('#fullScreenImg') as any).style.width = this.imgDefaultSize.width + 'px'
    this.fullscreenContainer.scroll(this.imgDefaultSize.width / 10, this.imgDefaultSize.height / 10)
  }

  outFullscreen () {
    if ('cancelFullScreen' in document) {
      // @ts-ignore:
      document.cancelFullScreen()
    } else if ('mozCancelFullScreen' in document) {
      // @ts-ignore:
      document.mozCancelFullScreen()
    } else if ('webkitCancelFullScreen' in document) {
      // @ts-ignore:
      document.webkitCancelFullScreen()
    } else {
      this.onFullscreenChange()
    }
  }

  onFullscreenChange () {
    // @ts-ignore
    const fullscreenElement = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement

    if (!fullscreenElement) {
      this.$emit('close')
      document.removeEventListener('fullscreenchange', this.onFullscreenChange)
      document.removeEventListener('mozfullscreenchange', this.onFullscreenChange)
      document.removeEventListener('webkitfullscreenchange', this.onFullscreenChange)
      document.removeEventListener('msfullscreenchange', this.onFullscreenChange)
    }
  }
}
