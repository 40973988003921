
import { Component, Vue } from 'nuxt-property-decorator'
import LayoutMenuBanner from '~/components/layout/menu/MenuBanner.vue'
import MenuContent from '~/components/layout/menu/MenuContent.vue'
import LayoutSearch from '~/components/layout/LayoutSearch.vue'

@Component({
  components: { LayoutSearch, MenuContent, LayoutMenuBanner }
})
export default class extends Vue {
  showMenu: boolean = false

  routing () {
    history.length === 1 ? this.$router.replace('/') : this.$router.back()
  }

  get isIOSDevice () {
    return process.client && ([
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform) || (navigator.userAgent.includes('Mac') && 'ontouchend' in document))
  }
}
