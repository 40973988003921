
import { Component, Vue } from 'nuxt-property-decorator'
import vSelect from 'vue-select'
import { Artist } from '~/types/Artist'

@Component({
  name: 'LayoutSearch',
  components: {
    vSelect
  }
})
export default class extends Vue {
  artists: Artist[] = []
  loading: boolean = false

  loadData () {
    if (this.artists.length) {
      return
    }

    this.loading = true
    this.$axios.get(process.env.API_ENDPOINT + '/api/v3/artists/list', {
      // @ts-ignore
      requireToken: false
    }).then((response) => {
      this.loading = false
      this.artists.push(...(response.data || []))
      this.artists.sort((a, b) => {
        const key = 'name_' + this.$i18n.locale
        return a[key] > b[key] ? 1 : -1
      })
    })
  }

  optionSelect (option) {
    this.$emit('select')
    this.$router.push(this.localePath('/artists/' + option.url))
  }
}
