import { render, staticRenderFns } from "./LayoutHeader.vue?vue&type=template&id=08d41777&scoped=true&"
import script from "./LayoutHeader.vue?vue&type=script&lang=ts&"
export * from "./LayoutHeader.vue?vue&type=script&lang=ts&"
import style0 from "./LayoutHeader.vue?vue&type=style&index=0&id=08d41777&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08d41777",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LayoutLang: require('/var/www/arthall/arthall.front/components/layout/LayoutLang.vue').default,LayoutMenu: require('/var/www/arthall/arthall.front/components/layout/menu/Menu.vue').default})
