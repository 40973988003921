
import { Component, Vue } from 'nuxt-property-decorator'

@Component
export default class extends Vue {
  showPopup: boolean = false

  triggerClick () {
    this.showPopup = !this.showPopup

    if (this.showPopup) {
      window.addEventListener('click', this.outsideClickHandler)
    } else {
      window.removeEventListener('click', this.outsideClickHandler)
    }
  }

  outsideClickHandler () {
    this.showPopup = false
    window.removeEventListener('click', this.outsideClickHandler)
  }
}
